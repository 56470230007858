<template>
  <AppTemplate jumbotron-label1="menu.profile">
    <div class="main main-raised">
      <div>
        <div class="md-layout-item">
          <md-content>
            <div>
              <b>{{ $t("setting.name") }}: </b>{{ $store.state.user.firstname }}
              {{ $store.state.user.lastname }}
            </div>
            <div><b>Email: </b>{{ $store.getters.get_user }}</div>
            <div>
              <b>{{ $t("setting.language") }}</b>
            </div>
            <md-field>
              <md-select
                v-model="selected_language"
                name="Language"
                id="chooseLanguage"
                :placeholder="$t('setting.language')"
              >
                <md-option value="en">English</md-option>
                <md-option value="fr">Français</md-option>
              </md-select>
            </md-field>
            <!-- Logout Password button  -->
            <md-button class="md-primary md-round" @click="do_logout">
              <i class="material-icons">logout</i>
              {{ $t("auth.logout") }}
            </md-button>
            <!-- Change Password button  -->
            <!--
            <md-button class="md-primary md-round" @click="invert_modal()">
              {{ $t("settings.reset") }}
            </md-button>
            
          <div
            v-if="classicModal"
            class="main main-raised bg"
            style="text-align:center"
          >
            <PasswordChanger></PasswordChanger>
          </div>
          -->
          </md-content>
        </div>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import microlearning_mixin from "@/mixins/microlearning_mixin";
//import PasswordChanger from "@/components/PasswordChanger.vue";
import config from "@/config";

export default {
  name: "Profile",
  mixins: [microlearning_mixin],
  components: {
    AppTemplate
    //    PasswordChanger,
  },
  watch: {
    selected_language: function(newVal) {
      //call the changeLanguage function only if the language selected is different
      if (newVal) this.changeLanguage();
    }
  },
  data() {
    return {
      selected_language: this.$i18n.locale,
      classicModal: false
    };
  },
  created() {
    document.title = config.title + " - Profile";
  },
  methods: {
    invert_modal() {
      this.classicModal = !this.classicModal;
    },
    changeLanguage() {
      this.$i18n.locale = this.selected_language;
      localStorage.setItem("user_language", this.selected_language);
    },
    do_logout() {
      this.$store.commit("logout");
      this.$router.push({ name: "Home" });
      this.$store.commit("info", this.$t("auth.logout_message"));
    }
  }
};
</script>
<style scoped lang="scss">
.main-raised {
  padding: 60px;
}
</style>
