<template>
  <div v-show="visible">
    <div v-show="visible" class="container content-band">
      <h2 v-show="title" class="band-title">{{ $t(title) }}</h2>
      <div v-if="nugget_list.length > 0">
        <div :id="`flicking-wrapper-${index}`">
          <flicking
            ref="flicking"
            :options="{
              align: align,
              center: center,
              circular: circular,
              bound: bound,
              moveType: ['freeScroll'],
              deceleration: deceleration,
              defaultIndex: -1,
              disableOnInit: disable_inputs
            }"
            :plugins="plugins"
            :viewportTag="'div'"
            :cameraTag="'div'"
            :key="flicking_dummy"
          >
            <div
              v-for="nugget in nugget_list"
              :key="nugget.version_id"
              class="carousel-slide"
            >
              <div v-if="nugget[0] != null" class="nugget-container">
                <nugget-card
                  :nugget="nugget[0]"
                  :rotate_state="nugget.rotate_state"
                  @rotated="nugget_rotated(nugget, $event)"
                ></nugget-card>
              </div>
            </div>
            <div slot="viewport" class="flicking-pagination"></div>
          </flicking>
          <span
            class="flicking-arrow flicking-arrow-prev is-outside"
            :class="arrow_class"
          ></span>
          <span
            class="flicking-arrow flicking-arrow-next is-outside"
            :class="arrow_class"
          ></span>
        </div>
        <div v-if="!loaded">
          <loading></loading>
        </div>
      </div>
      <div v-else class="error">
        <h4>{{ $t("no_nuggets") }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
import NuggetCard from "./NuggetCard";
import { Flicking } from "@egjs/vue-flicking";
import { Arrow, Pagination } from "@egjs/flicking-plugins";
import Loading from "@/components/Loading";
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/flicking-plugins/dist/pagination.css";

export default {
  name: "NuggetSlider",
  components: { Flicking, NuggetCard, Loading },
  props: ["nugget_list", "index", "limit", "center", "title"],
  data() {
    var deceleration = 0.006;
    if (window.innerWidth < 992) deceleration = 0.003;
    return {
      loaded: false,
      visiblePanelsCount: 0,
      deceleration: deceleration,
      flicking_dummy: 0
    };
  },
  watch: {
    visiblePanelsCount() {
      this.init();
    }
  },
  methods: {
    spy_end() {},
    init() {
      if (this.visiblePanelsCount) {
        var flicking = this.$refs.flicking;
        flicking.moveTo(0);
        this.flicking_dummy++;
        this.loaded = true;
      }
    }
  },
  mounted() {
    // Make the visiblePanelsCount react on ui changes
    this.watch_interval = setInterval(() => {
      var flicking = this.$refs.flicking;
      if (flicking?.getPanel(0) && flicking.$el) {
        // flicking.viewport.width is not responsive to display changes
        const width = flicking.$el.getBoundingClientRect().width;
        this.visiblePanelsCount = Math.floor(
          width / flicking.getPanel(0).sizeIncludingMargin
        );
      } else {
        this.visiblePanelsCount = 0;
      }
    }, 50);
  },
  destroyed: function() {
    clearTimeout(this.watch_interval);
  },
  computed: {
    // Is this slider displayed
    visible() {
      return this.nugget_list.length >= this.limit;
    },
    // Is this slider is circular
    circular() {
      // circular is the opposite of the center property
      // Except when we see only one half of the last nugget card in the viewport
      if (this.visiblePanelsCount == this.nugget_list.length - 1) return false;
      else return !this.center;
    },
    // Becase we cannot set circular and bound
    bound() {
      return this.circular ? undefined : true;
    },
    plugins() {
      // If there is enough nuggets in the category we add arrows and pagination
      if (this.visiblePanelsCount < this.nugget_list.length) {
        return [
          new Arrow({
            moveCount: this.visiblePanelsCount - 1,
            parentEl: document.getElementById("flicking-wrapper-" + this.index)
          }),
          new Pagination({ type: "bullet" })
        ];
      }
      return [];
    },
    // Disable inputs
    disable_inputs() {
      return this.visiblePanelsCount >= this.nugget_list.length;
    },
    // Show or hide the slider arrows
    arrow_class() {
      return {
        hidden: this.disable_inputs
      };
    },
    // Panels alignment in slider
    align() {
      return this.center ? { camera: "50%", panel: "0%" } : "prev";
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/nugget.scss";

@import "../assets/scss/nugget_slider.scss";

.error {
  margin: 50px;
  text-align: center;
}
</style>
