<template>
  <div class="author-info md-layout-item md-size-100 md-small-size-100 mx-auto">
    <profile-card
      card-plain
      type="horizontal"
      :small-img="true"
      :image-link="imglink"
      :shadow-normal="false"
      :no-colored-shadow="false"
      :card-image="author.has_thumbnail ? author.thumbnail_url : null"
      :class="{ 'no-thumbnail': !author.has_thumbnail }"
    >
      <template slot="cardContent">
        <h4 class="card-title title">
          <FullName :person="author" :link="true"></FullName>
        </h4>
        <h6 class="card-category text-muted">
          {{ $t("author")
          }}<span v-if="author.department"> - {{ author.department }} </span>
        </h6>
        <div
          class="card-description bio"
          v-if="author.bio"
          v-html="truncatedBio"
        ></div>
        <p class="card-description bio" v-if="author.bio">
          <router-link
            :key="author.email"
            :to="{
              name: 'Author',
              params: this.author_link_params(author),
              hash: '#app_top'
            }"
            ref="link"
          >
            {{ $t("see_more") }}
          </router-link>
        </p>
        <h4 class="link" v-if="author.website">
          <a v-if="isLinkedIn(author.website)" :href="author.website" target="_blank">
            <i class="fab fa-linkedin nugget-nav-tab-icon"></i>
          </a>
          <a v-else :href="author.website" target="_blank">
            {{ author.website }}
          </a>
        </h4>
      </template>
    </profile-card>
  </div>
</template>
<script>
import microlearning_mixin from "@/mixins/microlearning_mixin";
import FullName from "@/components/FullName";
import ProfileCard from "@/plugins/material-kit/components/cards/ProfileCard";
import utils from "@/utils";

export default {
  name: "AuthorInfo",
  props: ["author"],
  mixins: [microlearning_mixin],
  components: { FullName, ProfileCard },
  computed: {
    website() {
      return this.get_website(this.author);
    },
    imglink() {
      let route = this.author_link_params(this.author);
      // TODO: find a better way
      return route ? `/author/${route.slug}/${route.author_id}#app_top` : "#";
    },
    truncatedBio() {
      return utils.truncate(this.author.bio, 120, "...");
    }
  },
  methods: {
    isLinkedIn(url) {
      return url.includes('linkedin.com');
    }
  }
};
</script>
