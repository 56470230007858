import microlearning_mixin from "@/mixins/microlearning_mixin";
export default {
  mixins: [microlearning_mixin],
  props: ["params", "title", "with_aggregates"],
  data() {
    return {
      nuggets: [],
      pages: undefined,
      loading: false,
      page: 0
    };
  },
  watch: {
    // Reloads data on parameters change
    params() {
      this.page = 0;
      this.nuggets = [];
      this.load();
      this.load_aggregates();
    }
  },
  async created() {
    this.spy_end();
    // Resets on credentials change
    this.on_login(this.reset);
    // Load data
    this.reset();
  },
  methods: {
    // Resets the nugget list
    async reset() {
      if (this.loading) {
        this.reload = true;
        return;
      } else {
        this.nuggets = [];
        this.loading = false;
        this.pages = undefined;
        this.page = 0;
        this.load();
        this.load_aggregates();
      }
    },
    async load() {
      // Loads nuggets
      if (this.loading) {
        this.reload = true;
        return;
      }
      this.loading = true;
      var search_params = Object.assign({}, this.params, { page: this.page });
      var promise = this.search_nuggets(search_params)
        .then(async response => {
          this.nuggets = this.nuggets.concat(response.items);

          const promises = [];
          for (var i = 0; i < this.nuggets.length; i++) {
            promises.push(this.load_nugget_authors(this.nuggets[i]));
            this.nuggets[i].rotate_state = false;
          }
          await Promise.all(promises);

          // Reload properties so vue can update dom
          const old_nuggets = this.nuggets;
          this.nuggets = [];
          for (var j = 0; j < old_nuggets.length; j++) {
            this.nuggets.push(Object.assign({}, old_nuggets[j]));
          }

          this.$emit("response", response);
          this.pages = response.pages;
          this.page++;
          this.loading = false;
          // Reload if a load request was received during this one
          if (this.reload) {
            this.reload = false;
            this.reset();
          } else {
            this.$emit("ready");
          }

          this.nugget_generated(this.nuggets);
        })
        .catch(e => {
          console.log(e);
          this.$emit("failed");
          this.loading = false;
          this.pages = 0;
        });
      return promise;
    },
    load_aggregates() {
      // Loads nugget aggregates
      if (this.with_aggregates) {
        var agg_params = {};
        /*
        TODO: Should we consider only the fulltext search for potential aggregations?
        if (this.params && this.params.fulltext) {
          agg_params["fulltext"] = this.params.fulltext;
        }
        */
        agg_params = this.params;
        this.search_nuggets(agg_params).then(response => {
          this.$emit("aggregations", response.aggregations);
        });
      }
    },
    nugget_rotated(nugget, state) {
      if (state == true) {
        this.nuggets.forEach(nugget => (nugget.rotate_state = false));
        nugget.rotate_state = true;
      }
      this.nuggets = [].concat(this.nuggets);
    },
    // This should be implemented by the component
    spy_end() {},
    // This should be implemented by the component
    nugget_generated() {}
  }
};
