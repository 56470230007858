import cache from "js-cache";
import config_mixin from "@/mixins/config_mixin";
import utils from "@/utils";

var handle = function(promise) {
  // TODO: Error handling
  return promise.then(response => {
    return response.data;
  });
};
var make_url = function(path, params) {
  var query = "";
  if (params !== undefined) {
    query = `?${new URLSearchParams(params).toString()}`;
  }
  return `${path}${query}`;
};
var get = function(axios, path, params) {
  return handle(axios.get(make_url(path, params)));
};
var post = function(axios, path, data, params) {
  return handle(axios.post(make_url(path, params), data));
};
var string_to_slug = function(str) {
  if (str === undefined) return "";
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export default {
  mixins: [config_mixin],
  data() {
    return {
      search_fulltext: undefined
    };
  },
  watch: {
    $route: function(route) {
      if (route.query.fulltext) {
        this.search_fulltext = route.query.fulltext;
      } else {
        this.search_fulltext = undefined;
      }
    }
  },
  methods: {
    authenticate_be(payload) {
      return post(this.$axios, `/auth/token`, payload)
        .then(user => {
          // Store actualization
          this.$store.commit("login", user);
          // Success message
          this.$store.commit(
            "info",
            this.$t("auth.success", { username: user.email })
          );
          // Axios configuration
          this.$axios.defaults.headers.common.Authorization = `JWT ${user.token}`;

          // Redirection if necessary
          if (this.$route.query.next) {
            window.location.href = this.$route.query.next;
          }
          return user;
        })
        .catch(e => {
          console.log(e);
          this.$store.commit("error", this.$t("auth.error"));
        });
    },
    authenticate(username, password) {
      return this.authenticate_be({ username, password });
    },
    authenticate_token(token) {
      return this.authenticate_be({ token });
    },
    password_email(type, email) {
      const redirect = `${this.config.publicUrl}/change_password`;
      return post(this.$axios, `/auth/${type}_email`, { email, redirect })
        .then(() => {
          this.$store.commit(
            "info",
            this.$t(`auth.${type}.email_success`, { email })
          );
          return true;
        })
        .catch(e => {
          // Unknown error
          let error = "unexpected_error";
          let params = {};
          if (e.response.data !== undefined && e.response.data.error) {
            error = `auth.${type}.error_${e.response.data.error}`;
            params = e.response.data.params;
          }
          this.$store.commit("error", this.$t(error, params));
          return false;
        });
    },
    sign_up_email(email) {
      return this.password_email("sign_up", email);
    },
    reset_password_email(email) {
      return this.password_email("reset_password", email);
    },
    get_account() {
      return get(this.$axios, "/account/me");
    },
    check_current_token_validity() {
      if (this.$store.getters.get_user) {
        this.get_account().catch(() => {
          this.$store.commit("logout");
        });
      }
    },
    update_account(account) {
      return post(this.$axios, `/account/me`, account)
        .then(() => {
          this.$store.commit("info", this.$t("user.account_updated"));
          return true;
        })
        .catch(e => {
          // Unknown error
          let error = "unexpected_error";
          if (e.response.data !== undefined && e.response.data.error) {
            error = `auth.sign_up.error_${e.response.data.error}`;
          }
          this.$store.commit("error", this.$t(error));
          return false;
        });
    },
    get_cached(thing_str, ...ids) {
      if (!Array.isArray(ids)) ids = [ids];
      var cache_key = `${thing_str}_` + ids.join("_");
      var thing = cache.get(cache_key);
      // If cache is hit
      if (thing != undefined) return Promise.resolve(thing);
      // If not
      else {
        return this[`get_${thing_str}`](...ids).then(obj => {
          cache.set(cache_key, obj, 60000);
          return obj;
        });
      }
    },
    search_partners() {
      var is_producer = true;
      return get(this.$axios, `/naas/structures`, { is_producer });
    },
    search_nuggets(params) {
      if (params === undefined) params = {};
      if (params.page_size === undefined) params.page_size = 40;
      params.is_default_version = true;
      // Convert params into array compatible params
      const final_params = [];
      for (const key in params) {
        const values = params[key];
        if (Array.isArray(values)) {
          values.forEach(value => {
            final_params.push([key, value]);
          });
        } else final_params.push([key, values]);
      }
      return get(this.$axios, `/naas/nuggets/search`, final_params);
    },
    get_config() {
      return get(this.$axios, `/config`);
    },
    get_nugget(nugget_id) {
      return get(this.$axios, `/naas/nuggets/${nugget_id}/default_version`);
    },
    get_nugget_with_open_education_information(nugget_id) {
      return Promise.all([
        get(this.$axios, `/naas/nuggets/${nugget_id}/default_version`),
        get(this.$axios, `/naas/nuggets/${nugget_id}/open_education`)
      ]).then(values => {
        let [nugget, open_education_info] = values;
        nugget.open_education = open_education_info;
        return nugget;
      });
    },
    get_structure(structure_id) {
      return get(this.$axios, `/naas/structures/${structure_id}`);
    },
    get_person(email) {
      return get(this.$axios, `/naas/persons/${email}`);
    },
    get_vocabulary_entry(vocabulary_id, entry_id) {
      return get(
        this.$axios,
        `/naas/vocabularies/${vocabulary_id}/${entry_id}`
      );
    },
    get_structure_cached(structure_id) {
      return this.get_cached("structure", structure_id);
    },
    get_person_cached(email) {
      return this.get_cached("person", email);
    },
    get_vocabulary_entry_cached(vocabulary_id, entry_id) {
      return this.get_cached("vocabulary_entry", vocabulary_id, entry_id);
    },
    get_lti_data(nugget_id) {
      return get(this.$axios, `/lti/${nugget_id}`);
    },
    get_licence(licence) {
      return get(this.$axios, `/naas/licences/${licence}`);
    },
    get_licence_image(img_link) {
      return get(this.$axios, `/naas/resources/${img_link}`);
    },
    post_xapi_statement(verb, version_id, body) {
      return post(this.$axios, `/xapi/${verb}/${version_id}`, body);
    },
    newsletter(email) {
      return post(this.$axios, `/newsletter`, {
        email: email
      });
    },
    async get_licence_info() {
      const nugget_licence = this.nugget.access_licence.rights
        .toString()
        .replaceAll(",", "-")
        .toUpperCase();
      var licence_info = await this.get_licence(nugget_licence);
      licence_info.alt = nugget_licence;

      switch (this.$i18n.locale) {
        case "fr":
          licence_info.link = licence_info.fr;
          break;
        default:
          licence_info.link = licence_info.en;
          break;
      }
      return licence_info;
    },
    async get_nugget_average_score(nugget_id) {
      var res = {};
      if (this.config.use_stats) {
        var response = await get(
          this.$axios,
          `/naas/stats/nuggets/${nugget_id}/avg_rating`
        );
        res.score = Math.round(response);
        res.votes = await get(
          this.$axios,
          `/naas/stats/nuggets/${nugget_id}/user_rate`
        );
      }
      return res;
    },
    //request elasticsearch to get nuggets by stats
    get_user_stats(email, indicator, params) {
      return get(
        this.$axios,
        `/naas/stats/learners/${email}/${indicator}`,
        params
      );
    },
    async get_user_stats_as_list(email, indicator, params) {
      const data = await this.get_user_stats(email, indicator, params);
      return data.map(obj => Object.keys(obj)[0]);
    },
    //get list of nugget by stats (adn_started or adn_completion or viewed_tags) with domain ("nuggets" or "tags")
    async refresh_nugget_list_stats(email, verb) {
      var arr = [];
      if (this.config.use_stats) {
        var params = {
          distribution: "nuggets",
          verb: verb
        };
        arr = await this.get_user_stats_as_list(
          email.toLowerCase(),
          "nb_unique_nuggets",
          params
        );
      }
      this.$store.commit(`${verb}_nuggets`, arr);
    },
    async refresh_user_stats() {
      if (this.$store.getters.get_user != null) {
        this.refresh_nugget_list_stats(
          this.$store.getters.get_user,
          "experienced"
        );
        this.refresh_nugget_list_stats(
          this.$store.getters.get_user,
          "completed"
        );
      }
    },
    nugget_link_params(nugget) {
      return { nugget_id: nugget.nugget_id, slug: string_to_slug(nugget.name) };
    },
    structure_link_params(structure) {
      return {
        structure_id: structure.structure_id,
        slug: string_to_slug(structure.name)
      };
    },
    author_link_params(author) {
      return {
        author_id: author.email,
        slug: string_to_slug(`${author.firstname} ${author.lastname}`)
      };
    },
    // Loads data about the nugget licences
    load_nugget_access_licence(nugget) {
      return get(
        this.$axios,
        `/naas/nuggets/${nugget.nugget_id}/access_licence`
      ).then(licence => {
        nugget.access_licence = licence;
        return nugget;
      });
    },
    // A helper to load arrays of values into a model using promises
    load_model_data(model, field, callback) {
      var target_field = `${field}Data`;
      model[target_field] = [];
      const promises = [];
      for (var i = 0; i < model[field].length; i++) {
        promises.push(
          callback(model[field][i])
            .then(res => {
              model[target_field].push(res);
            })
            .catch(e => {
              console.error(`Cannot load data: ${model[field][i]}`);
              console.error(e);
            })
        );
      }
      return Promise.all(promises);
    },
    // Loads data about people referenced in the nugget
    // eg. authors, designers or reviewers
    load_nugget_people(nugget, field) {
      return this.load_model_data(nugget, field, this.get_person_cached);
    },
    load_nugget_authors(nugget) {
      return this.load_nugget_people(nugget, "authors");
    },
    load_nugget_reviewers(nugget) {
      return this.load_nugget_people(nugget, "reviewers");
    },
    // Loads data about the nugget involved structures
    load_nugget_structures(nugget, field) {
      return this.load_model_data(nugget, field, this.get_structure_cached);
    },
    load_nugget_managing_structures(nugget) {
      return this.load_nugget_structures(nugget, "managing_structures");
    },
    // Loads data about the nugget vocabularies
    load_nugget_vocabulary(nugget, field, vocabulary_id) {
      return this.load_model_data(nugget, field, entry_id =>
        this.get_vocabulary_entry(vocabulary_id, entry_id)
      );
    },
    load_nugget_domains(nugget) {
      return this.load_nugget_vocabulary(
        nugget,
        "domains",
        "nugget_domains_vocabulary"
      );
    },
    access_denied() {
      // Show error message
      this.$store.commit("error", this.$t("access_denied"));
      // Show login modal
      if (!this.$store.getters.authenticated) {
        this.$store.commit("login_modal", true);
      }
      // Redirect to home with option to be redirected to current page on login
      this.$router.push({ path: "/", query: { next: window.location.href } });
    },
    on_login(func) {
      this.$store.subscribe(mutation => {
        if (mutation.type == "login" || mutation.type == "logout") {
          func();
        }
      });
    },
    async update_nugget_status(new_status, nugget) {
      this.post_xapi_statement(new_status, nugget.version_id);
      if (new_status == "experienced")
        this.$store.commit("experienced_nugget", nugget.nugget_id);
      else if (new_status == "completed")
        this.$store.commit("completed_nugget", nugget.nugget_id);
    },
    focus_asap(el) {
      window.setTimeout(() => el.focus(), 100);
    },
    get_website(obj) {
      return obj.website ? utils.prettyLink(obj.website) : "";
    }
  },
  computed: {
    fulltext_param() {
      var res = {};
      if (this.search_fulltext) res.fulltext = this.search_fulltext;
      return res;
    }
  }
};
