<template>
  <md-field
    v-if="nugget.multilanguages && nugget.multilanguages.length > 1"
    class="multilanguage-field"
  >
    <div class="my-select" @click="toggleOptions">
      {{ $t("language." + selected_language) }}
      <i class="md-icon md-icon-font md-icon-image md-theme-default arrow-icon"
        ><svg
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 10l5 5 5-5z"></path>
          <path d="M0 0h24v24H0z" fill="none"></path></svg
      ></i>
    </div>
    <div class="my-options-language" v-if="showOptions">
      <div
        class="language-option"
        v-for="item in multilanguageSelectOptions"
        :key="item.language"
        :class="{ 'selected-language': item.language === selected_language }"
        @click="selectLanguage(item.language)"
      >
        {{ $t("language." + item.language) }}
      </div>
    </div>
  </md-field>
  <md-field v-else class="multilanguage-field">
    <div class="multilanguage-one-element">
      {{ $t("language." + nugget.language) }}
    </div>
  </md-field>
</template>
<script>
import router from "@/router";

export default {
  name: "NuggetMultilanguageSelect",
  props: ["nugget"],
  data() {
    return {
      multilanguageSelectOptions: null,
      showOptions: false
    };
  },
  computed: {
    selected_language: {
      get() {
        return this.nugget.language;
      },
      set(newVal) {
        if (newVal && newVal !== this.nugget.language) {
          const language = this.nugget.multilanguages.find(
            obj => obj.language === newVal
          );
          // redirect to the same page route
          router.push({
            name: this.$route.name,
            params: { nugget_id: language.nugget_id }
          });
        }
      }
    }
  },
  watch: {
    selected_language() {
      this.multilanguageSelectOptions = this.nugget.multilanguages;
      this.multilanguageSelectOptions.push({
        language: this.nugget.language,
        nugget_id: this.nugget.nugget_id
      });
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    selectLanguage(language) {
      this.selected_language = language;
      this.showOptions = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showOptions = false;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/multilanguage.scss";
</style>
