<template>
  <AppTemplate jumbotron-label1="partners">
    <div class="main main-raised page" ref="container">
      <!-- No Partners -->
      <div v-if="no_partners" class="text-center no-partners">
        <h3>{{ $t("no_partners") }}</h3>
        <i class="material-icons no-partners-icon"
          >sentiment_very_dissatisfied</i
        >
      </div>
      <!-- Partners list -->
      <div id="search_results">
        <partners-list
          partnersClass="md-layout-item partners-container"
        ></partners-list>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import PartnersList from "@/components/PartnersList";
import config from "@/config";

export default {
  name: "Partners",
  components: {
    AppTemplate,
    PartnersList
  },
  data() {
    return {
      state: {},
      partners: undefined
    };
  },
  created() {
    document.title = config.title + " - Partners";
  },
  computed: {
    no_partners() {
      return this.partners != undefined && this.partners.length == 0;
    }
  }
};
</script>
<style scoped lang="scss">
.no-partners {
  padding-top: 90px;
}
.no-partners-icon {
  font-size: 90px;
}
</style>
