<template>
  <AppTemplate jumbotron-label1="search">
    <div class="main main-raised page" ref="container">
      <md-button
        v-show="has_aggregations"
        class="md-primary md-round md-just-icon filter-collapse-button"
        @click="filters_collapsed = !filters_collapsed"
      >
        <md-icon>list</md-icon>
      </md-button>
      <!-- Filters -->
      <div
        class="filters"
        :class="{ collapsed: filters_collapsed || !has_aggregations }"
      >
        <div class="filters-inner">
          <div
            v-for="(aggregation, aggregation_key) in aggregations"
            :v-if="aggregation.buckets"
            :key="aggregation_key"
          >
            <a
              href="javascript:;"
              @click="switch_aggregation_visibility(aggregation)"
              data-toggle="dropdown"
            >
              <h6 class="aggregation-title">
                {{ $t("nugget." + aggregation.name) }}
                <md-icon v-if="aggregation.visible">remove</md-icon>
                <md-icon v-else>add</md-icon>
              </h6>
            </a>
            <Transition>
              <div
                :id="$id(aggregation_key)"
                v-show="aggregation.visible"
                class="aggregation-tags"
              >
                <div
                  v-if="aggregation_key == 'related_domains'"
                  id="related_domains"
                >
                  <span v-for="bucket in related_domains" :key="bucket.key">
                    <RelatedDomain
                      ref="relatedDomain"
                      :bucket="bucket"
                      :truncate_mobile_mode="truncate_mobile_mode"
                      :bucket_class="bucket_class"
                      @bucket-click="bucket_click"
                    ></RelatedDomain>
                  </span>
                </div>
                <span
                  v-for="(bucket, id, index) in aggregation.buckets"
                  :key="bucket.key"
                >
                  <a
                    href="javascript:;"
                    v-if="aggregation_key != 'related_domains'"
                    :class="{
                      'hide-authors': aggregation_key == 'authors' && index > 5
                    }"
                  >
                    <span
                      class="badge badge-pill badge-margin"
                      :class="bucket_class(bucket)"
                      :title="bucket.help"
                      @click="switch_facet(aggregation_key, bucket.query_value)"
                      >{{ truncate_mobile_mode(bucket.caption, 25) }}</span
                    >
                  </a>
                </span>
                <a
                  href="javascript:;"
                  id="show-more-authors"
                  class="clear-filters show-more"
                  v-if="aggregation_key == 'authors' && has_more(aggregation)"
                  @click="show_more_bucket()"
                >
                  {{ $t("show_more_authors") }}
                </a>
              </div>
            </Transition>
          </div>
          <div class="clear-filters" v-show="has_filters">
            <a href="javascript:;" @click="clear_filters()">{{
              $t("clear_filters")
            }}</a>
          </div>
        </div>
      </div>
      <!-- No nuggets -->
      <div id="search_results"></div>
      <div v-if="no_nuggets" class="text-center no-nuggets">
        <h3>{{ $t("no_nuggets") }}</h3>
        <i class="material-icons no-nuggets-icon"
          >sentiment_very_dissatisfied</i
        >
        <p class="no-nuggets-disconnected" v-if="!$store.getters.authenticated">
          {{ $t("no_nugget_disconnected") }}
        </p>
      </div>
      <!-- Nugget list -->
      <div class="nugget-list">
        <nugget-list
          :params="search_params"
          nuggetClass="nugget-container"
          @response="handle_response"
          @aggregations="handle_aggregations"
          :with_aggregates="true"
        ></nugget-list>
      </div>
    </div>
  </AppTemplate>
</template>
<script>
import AppTemplate from "@/components/AppTemplate.vue";
import NuggetList from "@/components/NuggetList";
import RelatedDomain from "@/components/RelatedDomain";
import search_mixin from "@/mixins/search_mixin";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import config from "@/config";

import utils from "@/utils";
// Useful for aggregation display order
var aggregations_definitions = [
  {
    name: "related_domains",
    bucket_key_to_ui(bucket_key, component) {
      return component
        .get_vocabulary_entry_cached("nugget_domains_vocabulary", bucket_key)
        .then(entry => (entry ? entry.label : bucket_key));
    }
  },
  {
    name: "level",
    bucket_key_to_ui: (bucket_key, component) =>
      component.$t(`nugget.levels.${bucket_key}`)
  },
  {
    name: "language",
    bucket_key_to_ui: (bucket_key, component) =>
      component.$t(`language.${bucket_key}`)
  },
  "tags",
  {
    name: "producers",
    aggregation_key: "producers",
    bucket_key_to_query: bucket_key => bucket_key,
    bucket_key_to_ui: (bucket_key, component) =>
      component
        .get_structure_cached(bucket_key)
        .then(structure => (structure ? structure.acronym : bucket_key))
  },
  {
    name: "authors",
    aggregation_key: "authors",
    bucket_key_to_query: bucket_key => bucket_key,
    bucket_key_to_ui: (bucket_key, component) =>
      component
        .get_person_cached(bucket_key)
        .then(
          author =>
            author ? `${author.firstname} ${author.lastname}` : bucket_key
          // TODO faire queslque chose
        )
        .catch(() => "?")
  },
  "references",
  {
    name: "type",
    bucket_key_to_ui: (bucket_key, component) =>
      component.$t(`nugget.types.${bucket_key}`)
  }
];
// Setting default values for simple aggregations
for (var i in aggregations_definitions) {
  var def = aggregations_definitions[i];
  if (typeof def === "string" || def instanceof String) def = { name: def };
  def.aggregation_key = def.aggregation_key || def.name;
  def.bucket_key_filter = def.bucket_key_filter || (bucket_key => bucket_key);
  def.bucket_key_to_ui = def.bucket_key_to_ui || (bucket_key => bucket_key);
  def.bucket_key_to_ui_help = def.bucket_key_to_ui_help || def.bucket_key_to_ui;
  def.bucket_key_to_query =
    def.bucket_key_to_query || (bucket_key => bucket_key);
  aggregations_definitions[i] = def;
}

export default {
  name: "Search",
  components: {
    AppTemplate,
    NuggetList,
    RelatedDomain
  },
  mixins: [search_mixin, microlearning_mixin],
  data() {
    var mobile_mode = window.innerWidth <= 768;
    return {
      state: {},
      aggregations: {},
      related_domains: {},
      nuggets: undefined,
      filters_collapsed: mobile_mode,
      mobile_mode
    };
  },
  created() {
    document.title = config.title + " - Search";
    window.addEventListener("resize", this.is_mobile_mode);
  },
  methods: {
    is_mobile_mode() {
      this.mobile_mode = window.innerWidth <= 768;
    },
    truncate_mobile_mode(text, size) {
      if (this.mobile_mode) return text;
      else return utils.truncate(text, size, "...");
    },
    // Updates aggregation data from response
    handle_response(response) {
      this.nuggets = response.items;
    },
    async handle_aggregations(network_aggregations) {
      if (network_aggregations) {
        var aggregations = Object.assign({});
        let aggregations_to_sort = new Array();
        var j = 1;
        let related_domains_list = Object.assign({});
        for (var i in aggregations_definitions) {
          // going through expected aggregations
          var aggregation_definition = aggregations_definitions[i];
          var aggregation_key = aggregation_definition.aggregation_key;
          var name = aggregation_definition.name;
          if (network_aggregations[aggregation_key]) {
            var state_buckets = network_aggregations[aggregation_key].buckets;
            // add bucket only if it has content
            if (state_buckets.length > 0) {
              let old_aggregation = this.aggregations[name];
              // Aggregations are not visible by default on smaller devices
              let visible = true;
              if (old_aggregation) visible = old_aggregation.visible;
              aggregations[name] = aggregations[name] || {
                buckets: {},
                visible,
                name: aggregation_definition.name
              };
              aggregations[name].id = j;
              j = j + 1;
              let aggregation_array = new Array();
              // Convert all buckets into data structures adapted to the UI
              for (var item_key in state_buckets) {
                var state_bucket = state_buckets[item_key];
                if (
                  aggregation_definition.bucket_key_filter(
                    state_bucket.key,
                    this
                  )
                ) {
                  // Bucket is not selected by default
                  state_bucket.selected = false;
                  // Convert key to UI readable string and add document count
                  state_bucket.caption = await aggregation_definition.bucket_key_to_ui(
                    state_bucket.key,
                    this
                  );
                  state_bucket.caption = `${state_bucket.caption} (${state_bucket.docCount})`;
                  // Convert key to a help text
                  state_bucket.help = await aggregation_definition.bucket_key_to_ui_help(
                    state_bucket.key,
                    this
                  );
                  // Convert key to query key (for actual search)
                  state_bucket.query_value = aggregation_definition.bucket_key_to_query(
                    state_bucket.key,
                    this
                  );
                  // Sort with children for the tree view
                  if (name == "related_domains")
                    this.create_children(related_domains_list, state_bucket, 2);

                  aggregation_array.push(state_bucket);
                }
              }
              aggregations_to_sort[name] = aggregation_array;
            }
          }
        }

        // Sort the aggregations alphabetically
        for (var aggregation_title in aggregations_to_sort) {
          let sorted_aggregation = aggregations_to_sort[aggregation_title].sort(
            (a, b) => {
              if (a.caption < b.caption) return -1;
              if (a.caption > b.caption) return 1;
              return 0;
            }
          );
          for (var index in sorted_aggregation)
            aggregations[aggregation_title].buckets[
              sorted_aggregation[index].key
            ] = sorted_aggregation[index];
        }

        // Sort the field of study alphabetically
        this.related_domains = Object.values(related_domains_list).sort(
          (a, b) => {
            if (a.caption < b.caption) return -1;
            else if (a.caption > b.caption) return 1;
            else return 0;
          }
        );

        this.aggregations = aggregations;
        this.synchronize_selection();
      }
    },
    create_children(related_domains_list, state_bucket, index) {
      if (
        typeof related_domains_list[state_bucket.key.slice(0, index)] ===
        "undefined"
      ) {
        // key not exist
        related_domains_list[state_bucket.key] = state_bucket;
        related_domains_list[state_bucket.key]["children"] = {};
      } else {
        // key exist so can create children inside this key
        this.create_children(
          related_domains_list[state_bucket.key.slice(0, index)]["children"],
          state_bucket,
          index + 1
        );
      }
    },
    bucket_click(bucket_key) {
      this.switch_facet("related_domains", bucket_key);
    },
    facet_exists(aggregation_key, bucket_key) {
      return (
        this.aggregations[aggregation_key] !== undefined &&
        this.aggregations[aggregation_key].buckets[bucket_key] !== undefined
      );
    },
    is_facet_selected(aggregation_key, bucket_key) {
      if (this.facet_exists(aggregation_key, bucket_key))
        return this.aggregations[aggregation_key].buckets[bucket_key].selected;
      return false;
    },
    set_facet_selected(aggregation_key, bucket_key, selected) {
      if (this.facet_exists(aggregation_key, bucket_key)) {
        this.aggregations[aggregation_key].buckets[
          bucket_key
        ].selected = selected;
        this.aggregations = Object.assign({}, this.aggregations);
      }
    },
    switch_facet(aggregation_key, bucket_key) {
      // Toogle the value of this bucket
      this.set_facet_selected(
        aggregation_key,
        bucket_key,
        !this.is_facet_selected(aggregation_key, bucket_key)
      );
      // Unselect all other bucket of this aggregation
      if (this.aggregations[aggregation_key]) {
        for (var other_bucket_key in this.aggregations[aggregation_key]
          .buckets) {
          if (bucket_key != other_bucket_key) {
            //this.set_facet_selected(aggregation_key, other_bucket_key, false);
          }
        }
      }
      this.synchronize_navigation();
    },
    // Synchronize UI selection with navigation
    // Part of this shoud be handled by the mixin
    synchronize_selection() {
      for (var aggregation_key in this.$route.query) {
        var bucket_keys = this.$route.query[aggregation_key];
        if (this.aggregations[aggregation_key]) {
          if (!Array.isArray(bucket_keys)) {
            this.set_facet_selected(aggregation_key, bucket_keys, true);
          } else {
            for (var i in bucket_keys) {
              this.set_facet_selected(aggregation_key, bucket_keys[i], true);
            }
          }
        }
      }
    },
    // Synchronize navigation with UI selection
    synchronize_navigation() {
      var query = {};
      for (var aggregation_key in this.aggregations) {
        for (var item_key in this.aggregations[aggregation_key].buckets) {
          var item = this.aggregations[aggregation_key].buckets[item_key];
          if (item.selected) {
            query[aggregation_key] = query[aggregation_key] || [];
            query[aggregation_key].push(item.query_value);
          }
        }
      }
      var search_to = {
        name: "Search",
        query: Object.assign({}, query, this.search_fulltext_param)
      };
      this.$router.push(search_to);
    },
    bucket_class(bucket) {
      var mode = bucket.selected ? "info" : "default";
      var key = `badge-${mode}`;
      var clazz = {};
      clazz[key] = true;
      return clazz;
    },
    clear_filters() {
      // Unselect all buckets in all aggregations
      for (var aggregation_key in this.aggregations) {
        for (var bucket_key in this.aggregations[aggregation_key].buckets) {
          this.set_facet_selected(aggregation_key, bucket_key, false);
        }
      }

      // Helper function to recursively collapse children
      const collapseChildren = relatedDomains => {
        relatedDomains.forEach(relatedDomain => {
          relatedDomain.showChildren = false;
          if (relatedDomain.$children.length > 0) {
            collapseChildren(relatedDomain.$children);
          }
        });
      };
      // Find the root level RelatedDomain components and collapse their children
      const rootRelatedDomains = this.$refs.relatedDomain;
      collapseChildren(rootRelatedDomains);

      this.synchronize_navigation();
    },
    switch_aggregation_visibility(aggregation) {
      aggregation.visible = !aggregation.visible;
      this.aggregations = Object.assign({}, this.aggregations);
    },
    has_more(aggregation) {
      if (Object.keys(aggregation["buckets"]).length > 6) {
        return true;
      } else {
        return false;
      }
    },
    show_more_bucket() {
      var hide_button = document.getElementsByClassName("hide-authors");
      var is_visible = hide_button[0].style.display == "inline";

      for (var i = 0; i < hide_button.length; i++) {
        if (is_visible) {
          hide_button[i].style.display = "none";
          document.getElementById("show-more-authors").innerHTML = this.$t(
            "show_more_authors"
          );
        } else {
          hide_button[i].style.display = "inline";
          document.getElementById("show-more-authors").innerHTML = this.$t(
            "hide_authors"
          );
        }
      }
    }
  },
  computed: {
    search_extra_params() {
      var params = Object.assign({}, this.$route.query);
      return params;
    },
    no_nuggets() {
      return this.nuggets != undefined && this.nuggets.length == 0;
    },
    has_aggregations() {
      for (var key in this.aggregations) {
        if (this.aggregations[key].buckets) return true;
      }
      return false;
    },
    has_filters() {
      for (var aggregation_key in this.aggregations) {
        for (var bucket_key in this.aggregations[aggregation_key].buckets) {
          if (this.is_facet_selected(aggregation_key, bucket_key)) return true;
        }
      }
      return false;
    }
  }
};
</script>
<style scoped lang="scss">
@import "../assets/scss/facets.scss";

.main-raised {
  overflow: hidden;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.no-nuggets {
  padding-top: 90px;
}
.no-nuggets-icon {
  font-size: 90px;
}
.no-nuggets-disconnected {
  font-size: medium;
  background-color: $brand-danger;
  color: white;
  border-radius: 6px;
  white-space: pre-line;
  width: fit-content;
  padding: 10px;
  margin: auto;
}
</style>
