<template>
  <AppTemplate jumbotron-label1="menu.my_nuggets">
    <div class="main main-raised">
      <div v-if="config.use_stats">
        <div
          v-for="(category, index) in categories"
          :id="category.title"
          :key="index"
          class="md-layout-item"
        >
          <NuggetStatsSlider
            :title="category.title"
            :limit="0"
            :center="false"
            :nugget_class="{ 'col-md-3': true }"
            :nugget_list="category.list"
            :index="0"
            @ready="ready"
            @failed="ready"
          ></NuggetStatsSlider>
        </div>
      </div>
    </div>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/components/AppTemplate.vue";
import microlearning_mixin from "@/mixins/microlearning_mixin";
import NuggetStatsSlider from "@/components/NuggetStatsSlider";
import config from "@/config";

export default {
  name: "Profile",
  mixins: [microlearning_mixin],
  components: {
    AppTemplate,
    NuggetStatsSlider
  },
  data() {
    return {
      categories: [],
      experienced_nugget_list: [],
      completed_nugget_list: []
    };
  },
  created() {
    document.title = config.title + " - My Nuggets";
    this.experienced_nugget_list = this.get_nugget_id_list(
      this.get_experienced
    );
    this.completed_nugget_list = this.get_nugget_id_list(this.get_completed);
    this.set_categories(
      this.$t("menu.started_list"),
      this.experienced_nugget_list
    );
    this.set_categories(
      this.$t("menu.completed_list"),
      this.completed_nugget_list
    );
  },
  watch: {
    get_experienced(newVal) {
      this.experienced_nugget_list = this.get_nugget_id_list(newVal);
      this.set_categories(
        this.$t("menu.started_list"),
        this.experienced_nugget_list
      );
    },
    get_completed(newVal) {
      this.completed_nugget_list = this.get_nugget_id_list(newVal);
      this.set_categories(
        this.$t("menu.completed_list"),
        this.completed_nugget_list
      );
    }
  },
  methods: {
    set_categories(title, list) {
      if (list !== null) {
        const existingCategory = this.categories.find(
          cat => cat.title === title
        );

        if (existingCategory) {
          existingCategory.list = list;
        } else {
          this.categories.push({ title: title, list: list });
        }
      }
    },
    async get_nuggets(nugget_list) {
      var nugget = [];
      this.finished_nugget_list = nugget_list;
      for (var i = 0; nugget_list[i]; i++) {
        nugget.push(await this.search_nuggets({ nugget_id: nugget_list[i] }));
      }
      return nugget;
    },
    get_nugget_id_list(nugget_id_list) {
      var nugget_list = [];

      this.get_nuggets(nugget_id_list).then(values => {
        for (var i = 0; values[i]; i++) {
          nugget_list.push(values[i].items);
        }
      });
      return nugget_list;
    }
  },
  computed: {
    get_experienced() {
      return this.$store.getters.nuggets_status.unfinished;
    },
    get_completed() {
      return this.$store.getters.nuggets_status.completed;
    }
  }
};
</script>
<style scoped lang="scss">
.main-raised {
  padding: 60px;
}

@import "../assets/scss/nugget_slider.scss";

.hidden {
  opacity: 0;
}
.menu {
  background: none;
  a {
    color: white !important;
  }
}
.loader {
  padding: 100px;
}
.all_link {
  position: absolute;
  margin: -40px 0px 0px 0px;
  padding: 0px 30px 0px 30px;
  box-shadow: none;
}
.hello_msg {
  float: left;
  color: #ffffff;
  -webkit-animation: cssAnimation 5s forwards;
  animation: cssAnimation 5s forwards;
}
@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.navbar-brand {
  font-weight: 600;
}

.resize-mobile {
  font-size: 20px;
}

@media screen and (max-width: 991px) {
  .resize-mobile {
    margin-right: 5px;
    font-size: 12px;
  }
}
</style>
